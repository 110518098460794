import { defineStore } from 'pinia'

export const useUserProfileStore = defineStore('userProfile', {
  state: () => ({
    userProfile: {},
    branches: [],
    timeEntries: []
  }),
  actions: {
    resetUserProfile() {
      this.$reset()
    }
  }
})
