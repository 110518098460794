<template>
  <div background-color="white" class="w-full h-full">
    <th-datatable
      class="time-tracking-table"
      sortable
      expanding-row
      :expand-row-if="hasBreak"
      :headers="headers"
      :show-operations="false"
      :resource-limit="100"
      :resource-query="resourceQuery"
      resource="timetracking"
      :custom-resource="timeTrackingResource"
      :transform-fetched-data="transformFetchedData"
      :get-handler-key="handlerKey"
      :custom-fetch-id="modelValue.id"
      transform-fetched-meta-allowed
      no-meta-check
      :locale="locale"
      show-filter
      :summary="summary"
      :summary-headers="summaryHeaders"
      :search-filters="filtersList"
      route-base="/user_profile/hours"
      do-route-filters
      prune-search-filters
      headers-filterable
      :headers-config="headersConfig"
      @headers-config="handleHeadersConfig"
      @loading-error="handleLoadingError"
    >
      <template #expanding-row="{ row }">
        <expanding-row :row="row" @update-row="updateRowEntries" />
      </template>
    </th-datatable>
  </div>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import ExpandingRow from './expanding-row'
import timeMixin from '@/views/timetracking/mixin/time-mixin'
import datatableHeadersConfig from '@/mixins/datatable-headers-config'

export default {
  components: {
    ExpandingRow
  },
  props: {
    modelValue: {
      type: Object,
      required: true
    }
  },
  setup() {
    const { headersConfig, handleHeadersConfig } = datatableHeadersConfig(
      'settings.headerFilters.user_profile.time_tracking'
    )
    const { headers, locale, getTotalOverallTime, hasBreak } = timeMixin()
    return {
      headersConfig,
      handleHeadersConfig,
      headers,
      locale,
      getTotalOverallTime,
      hasBreak
    }
  },
  data() {
    return {
      timeTrackingResource: th.timetracking(),
      handlerKey: 'get',
      summaryHeaders: [
        {
          field: 'total_break',
          label: this.$t('pages.timetracking.report.footer.total_break.title'),
          fallback: '--',
          truncate: true,
          align: 'right'
        },
        {
          field: 'total_worked',
          label: this.$t('pages.timetracking.report.footer.total_worked.title'),
          fallback: '--',
          truncate: true,
          align: 'right'
        }
      ],
      summary: [{ total_break: null, total_worked: null }]
    }
  },
  computed: {
    filtersList() {
      return [
        {
          name: 'date',
          prop: ['start', 'end'],
          type: 'daterange',
          label: this.$t(
            'pages.user_profile.user_info_time_tracking.filter.date'
          ),
          formatValue: (value) => this.$date.formatDateRange(value),
          modifyFilter: (filterObject) => ({
            start: filterObject.start,
            end: filterObject.end
          })
        }
      ]
    },
    resourceQuery() {
      return {
        staffId: this.modelValue.id,
        limit: 100
      }
    }
  },
  methods: {
    updateRowEntries(updatedRow) {
      updatedRow.row.breaks = updatedRow.breaks
    },
    handleLoadingError(err) {
      this.$logException(err, {
        trackError: false,
        message: this.$t('common.error.action.read.multiple', {
          resources: this.$t(
            'pages.user-profile.user-info.tab.time-tracking.title'
          )
        })
      })
    },
    // Used to create the summary (total_worked / total_break) for the footer
    transformFetchedData(data) {
      let overallBreaks = []
      let overallWorked = []
      data.forEach((day) => {
        overallBreaks.push(day.total_break)
        overallWorked.push(day.total_worked)
      })
      this.summary = [
        {
          total_break: this.getTotalOverallTime(overallBreaks),
          total_worked: this.getTotalOverallTime(overallWorked)
        }
      ]
      return data
    }
  }
}
</script>

<style scoped>
.time-tracking-table :deep(.el-table__body-wrapper) {
  overflow-y: auto !important;
}
.time-tracking-table :deep(.content),
.time-tracking-table :deep(.header) {
  margin: 0;
  margin-top: 20px;
}
</style>
