<template>
  <div class="mb-8">
    <h2 class="text-xl mb-8" v-text="permissionsTitle" />
    <div class="divide-y divide-gray-400">
      <el-row
        v-for="(permission, indexPermission) in permissions"
        :key="indexPermission"
        class="py-6 w-5/6"
      >
        <el-col :span="8">{{ permission.title }}</el-col>
        <el-col :span="16" class="pl-32">
          <div v-if="!permission.children" class="mb-2">
            {{ permission.description || permission.title }}
          </div>
          <div
            v-for="(action, indexAction) in permission.children"
            :key="indexAction"
            class="mb-3"
            :class="{ 'font-bold': type === 'user' }"
          >
            {{ action.description || action.title }}
            <div
              v-for="(subAction, indexSubAction) in action.children"
              :key="indexSubAction"
              class="ml-3 font-normal"
            >
              {{ subAction.description || subAction.title }}
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import intersect from 'just-intersect'
import {
  permissionsConfigStaff,
  permissionsConfigUser,
  allPermissionsGranted
} from '@/constants/permissions-constants'
export default {
  props: {
    type: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      permissionsConfig: {
        user: this.formatUserPermissions(permissionsConfigUser(this)),
        staff: permissionsConfigStaff(this)
      },
      allPermissions: {
        user: allPermissionsGranted,
        staff: ['staff:staff', ...allPermissionsGranted]
      },
      permissions: []
    }
  },
  computed: {
    permissionsTitle() {
      return this.type === 'staff'
        ? this.$t('pages.user_profile.user_info_dashboard.permissions.title')
        : this.$t('pages.user_profile.user_info_pos.permissions.title')
    }
  },
  mounted() {
    this.permissions = this.getFilteredPermissions()
  },
  methods: {
    getFilteredPermissions() {
      let permissions = this.$deepClone(this.permissionsConfig[this.type])
      let allPermissions = this.$deepClone(this.allPermissions[this.type])
      let scopes = this.$store.state.Auth.scopes
      // Don't filter if has all the permissions
      // Staff has one level of children, User has two levels of children
      if (!intersect(scopes, allPermissions).length) {
        permissions = permissions.filter((permission) => {
          if (scopes.includes(permission.scope)) {
            //permission over the parent
            return true
          } else {
            //filter children and decide if keep parent
            const children =
              permission.children?.filter((child) => {
                if (scopes.includes(child.scope)) {
                  //permission over the parent
                  return true
                } else {
                  //filter children and decide if keep parent
                  const subChildren =
                    child.children?.filter((subChild) =>
                      scopes.includes(subChild.scope)
                    ) || []
                  child.children = subChildren
                  return !!subChildren.length
                }
              }) || []
            permission.children = children
            return !!children.length
          }
        })
      }
      return permissions
    },
    // As user permissions are handling a different structure than staff
    // We are formatting them to fit the same pattern as the staff
    formatUserPermissions(permissions) {
      return Object.keys(permissions).map((parentKey) => {
        return {
          name: parentKey,
          scope: parentKey,
          title: `nav.main.items.${parentKey}.title`,
          children: permissions[parentKey]
        }
      })
    }
  }
}
</script>

<style scoped></style>
