<template>
  <base-card class="th-user-info overflow-hidden h-full">
    <div class="flex flex-col h-full">
      <th-tabs-form-wrapper
        v-model="tab"
        :tabs="formTabs"
        :has-border="false"
        class="mx-8"
      >
        <template #dashboard>
          <div class="h-full flex justify-center items-center overflow-y-auto">
            <dashboard ref="dashboard" v-model="localValue" />
          </div>
        </template>
        <template #pos>
          <div class="h-full flex justify-center items-center overflow-y-auto">
            <pos ref="pos" v-model="localValue" />
          </div>
        </template>
        <template #hours>
          <div class="h-full flex justify-center items-center">
            <hours v-if="showHours" ref="hours" v-model="localValue" />
          </div>
        </template>
        <template #sales>
          <div class="h-full flex justify-center items-center">
            <sales v-if="showSales" ref="sales" v-model="localValue" />
          </div>
        </template>
      </th-tabs-form-wrapper>
    </div>
  </base-card>
</template>

<script>
import Dashboard from './user-info-dashboard'
import Pos from './user-info-pos'
import Sales from './sales/user-info-sales'
import Hours from './time-tracking/user-info-time-tracking'
import BaseCard from '@/components/base-card'
import qs from 'qs'
import { startOfMonth } from 'date-fns'

export default {
  components: {
    Dashboard,
    Pos,
    Sales,
    Hours,
    BaseCard
  },
  props: {
    modelValue: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      tab: 'dashboard',
      showSales: false,
      showHours: false,
      formTabs: [
        {
          label: this.$t('pages.user-profile.user-info.tab.dashboard.title'),
          name: 'dashboard'
        },
        {
          label: this.$t('pages.user-profile.user-info.tab.pos.title'),
          name: 'pos'
        },
        {
          label: this.$t(
            'pages.user-profile.user-info.tab.time-tracking.title'
          ),
          name: 'hours'
        },
        {
          label: this.$t('pages.user-profile.user-info.tab.sales.title'),
          name: 'sales'
        }
      ]
    }
  },
  computed: {
    localValue: {
      get() {
        return this.modelValue
      },
      set(val) {
        this.$emit('update:modelValue', val)
      }
    }
  },
  watch: {
    tab: function (newTab) {
      //Avoid the auto trigger before having the correct filters for sales report & timetracking report
      if (newTab === 'sales') {
        this.showSales = true
        if (this.$route.name !== 'sales') {
          this.addParamsToLocation('sales', {
            filter: {
              date: this.defaultDate(),
              // query needs to be always filtered by the staff
              salesman: this.modelValue.id
            }
          })
        }
      } else if (newTab === 'hours') {
        this.showHours = true
        if (this.$route.name !== 'hours') {
          this.addParamsToLocation('hours', {
            filter: {
              date: this.defaultDate(),
              // query needs to be always filtered by the staff
              staffId: this.modelValue.id
            }
          })
        }
      } else if (newTab === 'dashboard') {
        this.addParamsToLocation('')
      }
    }
  },
  methods: {
    validate(cb) {
      this.$refs.dashboard.validate(function (valid) {
        cb(valid)
      })
    },
    selectTab(tab) {
      this.tab = tab
    },
    addParamsToLocation(path, params) {
      const query = params ? qs.stringify(params, { addQueryPrefix: true }) : ''
      this.$router.push(`/user_profile/${path}${query}`)
    },
    defaultDate() {
      return {
        end: new Date(),
        start: startOfMonth(new Date())
      }
    }
  }
}
</script>

<style scoped>
.th-user-info :deep(button:focus) {
  background-color: var(--navigation-bg-color);
}
</style>
