<template>
  <base-card class="items-center px-10 mb-5 mx-2 w-full">
    <div class="flex items-center mt-8 mb-2 w-full">
      <div class="h-full flex flex-col justify-between">
        <div class="mb-6">
          <div
            class="w-32 h-32 flex flex-col rounded-lg overflow-hidden p-2 border border-solid border-gray-400"
          >
            <th-image-upload-v2
              v-model="images"
              resource="staff"
              image-key="avatar"
              :default-image="defaultImage"
            />
          </div>
        </div>
        <div>
          <el-button
            text
            class="p-0 hidden"
            disabled
            v-text="$t('pages.user_profile.details.upload_photo')"
          />
        </div>
      </div>
      <div class="h-full flex flex-col justify-between pl-4">
        <div class="mb-4">
          <h1 class="text-sm my-0 leading-none font-semibold mb-1">
            {{ userProfile.firstname || '–' }}
          </h1>
          <h1 class="text-sm my-0 leading-none font-semibold mb-1">
            {{ userProfile.lastname || '–' }}
          </h1>
          <h2 class="text-sm font-thin my-0 mt-1" v-text="role || '-'" />
          <h2 class="text-sm font-thin my-0 text-gray-500 mt-5">
            {{ staffNumber }}
          </h2>
        </div>
      </div>
    </div>
    <div class="items-center mb-8 w-full text-sm">
      <el-row class="flex w-full mb-2">
        <el-col :lg="10">
          <label class="font-bold">
            {{ $t('pages.user_profile.details.branch') }}
          </label>
        </el-col>
        <el-col
          :lg="14"
          class="cursor-pointer"
          @click="changeCollapsedBranches()"
        >
          <div v-if="collapsedBranches">
            {{ getCollapsedPlusItemsName(branches) }}
          </div>
          <div v-else>
            <div v-for="branch in branches" :key="branch.id">
              {{ branch.name }}
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row class="flex w-full">
        <el-col :lg="10">
          <label class="font-bold">
            {{ $t('pages.user_profile.details.phone_number') }}
          </label>
        </el-col>
        <el-col :lg="14">
          <div>{{ phoneNumber }}</div>
        </el-col>
      </el-row>
    </div>
  </base-card>
</template>

<script>
import BaseCard from '@/components/base-card'
import { defaultImages } from '@/utils/people-default-images'

export default {
  components: { BaseCard },
  props: {
    modelValue: {
      type: Object,
      required: true
    },
    branches: {
      type: Array,
      default: () => []
    },
    role: {
      type: String,
      default: () => null
    }
  },
  data() {
    return {
      collapsedBranches: true
    }
  },
  computed: {
    userProfile: {
      get() {
        return this.modelValue
      },
      set(v) {
        this.$emit('update:modelValue', v)
      }
    },
    images: {
      get() {
        return this.modelValue.images || {}
      },
      set(v) {
        this.userProfile.images = v || {}
      }
    },
    defaultImage() {
      if (this.userProfile.gender === 'female') return defaultImages.female
      if (this.userProfile.gender === 'male') return defaultImages.male

      return defaultImages.neutral
    },
    phoneNumber() {
      return (
        this.userProfile.phonenumbers?.mobile ||
        this.userProfile.phonenumbers?.home ||
        this.userProfile.phonenumbers?.work ||
        this.userProfile.phonenumbers?.any ||
        '–'
      )
    },
    staffNumber() {
      let staffNumber
      if (this.userProfile.staff_number) {
        staffNumber = '#' + this.userProfile.staff_number
      } else {
        staffNumber = '–'
      }
      return staffNumber
    }
  },
  methods: {
    getCollapsedPlusItemsName(array) {
      let collapsedName
      if (array.length) {
        collapsedName = array[0].name
        if (array.length > 1) collapsedName += ', +' + (array.length - 1)
      } else {
        collapsedName = this.$t('components.available_in.everywhere')
      }
      return collapsedName
    },
    changeCollapsedBranches() {
      this.collapsedBranches = !this.collapsedBranches || !this.branches.length
    }
  }
}
</script>
