<template>
  <div v-if="currentBreaks.length">
    <el-row
      v-for="timeRow in currentBreaks"
      :key="timeRow.id"
      :gutter="20"
      type="flex"
      align="bottom"
      :class="{
        'border-b-2 border-solid border-gray-400':
          timeRow.id !== currentBreaks[currentBreaks.length - 1].id
      }"
      class="py-6"
    >
      <el-col class="break-expand-column" />
      <el-col class="break-expand-column text-right">
        <div class="font-bold mb-3 text-th-primary">
          {{ $t('pages.timetracking.report.entries.break_start') }}
        </div>
        <div>
          {{ getFormattedTime(timeRow.started_at) }}
        </div>
      </el-col>
      <el-col class="break-expand-column text-right">
        <div class="font-bold mb-3 text-th-primary">
          {{ $t('pages.timetracking.report.entries.break_end') }}
        </div>
        {{ getFormattedTime(timeRow.ended_at) }}
      </el-col>
      <el-col class="break-expand-column text-right"
        ><span>{{ timeRow.total_break }}</span></el-col
      >
      <el-col class="break-expand-column" />
    </el-row>
  </div>
</template>
<script>
import th from '@tillhub/javascript-sdk'
import isEmpty from 'just-is-empty'
import safeGet from 'just-safe-get'
import { getTimeDiffText } from '@utils/time-tracking-helper'
import timeMixin from '@/views/timetracking/mixin/time-mixin'
export default {
  props: {
    row: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  setup() {
    const { getFormattedTime } = timeMixin()
    return { getFormattedTime }
  },
  data() {
    return {
      currentBreaks: []
    }
  },
  computed: {
    staffId() {
      return safeGet(this.$store.state.Auth, 'staff') || null
    }
  },
  mounted() {
    this.loadEntries()
  },
  methods: {
    async loadEntries() {
      if (isEmpty(this.row.breaks)) {
        const date = this.$date.formatDate(this.row.date)
        const entries = await this.fetchEntryDetails(date)
        const breaks = entries.filter((element) => {
          if (element.type === 'break') {
            element.total_break = getTimeDiffText(
              element.started_at,
              element.ended_at,
              {
                hour: this.$t('common.hour.min'),
                minute: this.$t('common.minute.min')
              }
            )
            return true
          }
        })
        //Update the entries in the parent
        this.$emit('update-row', { row: this.row, breaks })
        this.currentBreaks = breaks
      } else {
        this.currentBreaks = this.row.breaks
      }
    },
    async fetchEntryDetails(date) {
      const query = { query: { date, deleted: false } }
      try {
        const { data } = await th.timetracking().getEntries(this.staffId, query)
        return data
      } catch (err) {
        this.$logException(err, {
          trackError: false,
          message: this.$t('common.error.action.read.single', {
            resource: this.$t('common.resource.timetracking.singular')
          })
        })
        return []
      }
    }
  }
}
</script>
<style scoped>
.break-expand-column {
  min-width: 120px;
}
</style>
