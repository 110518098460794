<template>
  <div class="w-1/2">
    <h2 class="text-xl">
      {{ $t('pages.user_profile.user_info_dashboard.title') }}
    </h2>
    <el-row class="my-8">
      <el-form ref="form" :model="localValue" :rules="rules">
        <el-form-item
          :label="$t('pages.user_profile.user_info_dashboard.email.label')"
          class="font-semibold"
          prop="username"
        >
          <el-input id="username" v-model="localValue.username" />
        </el-form-item>
        <el-form-item
          prop="secret"
          class="font-semibold"
          :label="$t('pages.user_profile.user_info_dashboard.password.label')"
        >
          <el-input
            id="secret"
            v-model="localValue.secret"
            autocomplete="off"
            autocorrect="off"
            autocapitalize="off"
            spellcheck="false"
            placeholder="●●●●●●●●●●●●"
            :type="showPassword ? undefined : 'password'"
          >
            <template #append>
              <el-button icon="View" @click="showPassword = !showPassword" />
            </template>
          </el-input>
          <div class="leading-tight text-sm text-gray-600 mt-3 ml-2">
            {{
              $t('pages.user_profile.user_info_dashboard.password.hint.content')
            }}
          </div>
        </el-form-item>
      </el-form>
    </el-row>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showPassword: false,
      rules: {
        username: [
          {
            required: true,
            message: this.$t(
              'pages.users_profile.form.email.validation.errors.required'
            ),
            trigger: 'blur'
          },
          {
            type: 'email',
            message: this.$t('common.forms.rules.email')
          },
          {
            max: 128,
            message: this.$t('common.forms.rules.max_length', { length: 128 })
          }
        ],
        secret: [
          {
            min: 5,
            max: 128,
            message: this.$t('common.forms.rules.min_max_length', {
              min: 5,
              max: 128
            }),
            trigger: 'blur'
          }
        ]
      }
    }
  },
  computed: {
    localValue: {
      get() {
        return this.modelValue
      },
      set(v) {
        this.$emit('update:modelValue', v)
      }
    }
  },
  methods: {
    validate(cb) {
      this.$refs.form.validate((valid) => {
        return cb(valid)
      })
    }
  }
}
</script>

<style scoped></style>
