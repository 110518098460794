<template>
  <div class="font-bold w-1/2">
    <div class="border-solid border-b-2 border-gray-600 my-4 pb-2">
      <el-row
        v-for="(item, index) in row.items"
        :key="index"
        class="flex pb-2 px-2"
      >
        <el-col :span="2">{{ index + 1 }}</el-col>
        <el-col :span="8" class="text-left">{{ item.name }}</el-col>
        <el-col :span="14" class="text-right">{{
          item._amount_total_gross
        }}</el-col>
      </el-row>
    </div>
    <el-row class="flex px-2">
      <el-col class="text-left">{{
        $t('pages.user_profile.user_info_sales.table.expand.total.label')
      }}</el-col>
      <el-col class="text-right">{{ row._total }}</el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  props: {
    row: {
      type: Object,
      required: false,
      default: undefined
    }
  }
}
</script>

<style scoped></style>
