<template>
  <div background-color="white" class="w-full h-full">
    <th-datatable
      ref="table"
      class="sales-table"
      sortable
      expanding-row
      :headers="headers"
      :show-operations="false"
      :resource-limit="100"
      :resource-query="resourceQuery"
      resource="analytics"
      :custom-resource="soldCartItemsResource"
      :transform-fetched-data="transformFetchedData"
      transform-fetched-meta-allowed
      no-meta-check
      headers-filterable
      :headers-config="headersConfig"
      :locale="locale"
      show-filter
      :summary="summary"
      :summary-headers="summaryHeaders"
      :search-filters="filtersList"
      route-base="/user_profile/sales"
      do-route-filters
      prune-search-filters
      @headers-config="handleHeadersConfig"
      @loading-error="handleLoadingError"
    >
      <template #expanding-row="{ row }">
        <expanding-row :row="row" />
      </template>
    </th-datatable>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import th from '@tillhub/javascript-sdk'
import qs from 'qs'
import safeGet from 'just-safe-get'
import ExpandingRow from './expanding-row'

//Query taken and formatted for a specific staff/salesman
const headersConfigPath = 'settings.headerFilters.user_profile.sales'

export default {
  components: {
    ExpandingRow
  },
  props: {
    modelValue: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      soldCartItemsResource: th.analyticsHandlers().analytics.reports
        .AnalyticsReportsTransactionsItems,
      headers: [
        {
          field: '_date_label',
          label: this.$t(
            'pages.user_profile.user_info_sales.table.hearder.date.label'
          ),
          fallback: '--',
          minWidth: 160,
          truncate: true,
          sortType: 'date'
        },
        {
          field: 'transaction_custom_id',
          label: this.$t(
            'pages.user_profile.user_info_sales.table.hearder.receipt.label'
          ),
          fallback: '--',
          minWidth: 160,
          align: 'right',
          truncate: true
        },
        {
          field: '_total',
          label: this.$t(
            'pages.user_profile.user_info_sales.table.hearder.total.label'
          ),
          fallback: '--',
          minWidth: 160,
          truncate: true,
          align: 'right',
          sortType: 'currency'
        }
      ],
      summaryHeaders: [
        {
          field: 'number_sales',
          label: this.$t(
            'pages.user_profile.user_info_sales.report.footer.number_sales.title'
          ),
          fallback: '--',
          truncate: true,
          align: 'right'
        },
        {
          field: 'avg_sales',
          label: this.$t(
            'pages.user_profile.user_info_sales.report.footer.avg_sales.title'
          ),
          fallback: '--',
          truncate: true,
          align: 'right'
        },
        {
          field: 'total_sales',
          label: this.$t(
            'pages.user_profile.user_info_sales.report.footer.total_sales.title'
          ),
          fallback: '--',
          truncate: true,
          align: 'right'
        }
      ],
      summary: [{ number_sales: null, avg_sales: null, total_sales: null }]
    }
  },
  computed: {
    ...mapGetters({
      locale: 'Config/getLocale',
      timeZone: 'Config/getTimeZone',
      localConfiguration: 'Config/getLocalConfiguration'
    }),
    headersConfig() {
      return safeGet(this.localConfiguration, headersConfigPath) || {}
    },
    parsedQuery() {
      const parsedQuery = (qs.parse(this.$route.query) || {}).filter
      return parsedQuery || {}
    },
    filtersList() {
      return [
        {
          name: 'date',
          prop: ['date_start', 'date_end'],
          type: 'daterange',
          label: this.$t('pages.user_profile.user_info_sales.filter.date'),
          formatValue: (value) => this.$date.formatDateRange(value),
          modifyFilter: (filterObject) => ({
            date_start: filterObject.start,
            date_end: filterObject.end
          })
        }
      ]
    },
    resourceQuery() {
      return {
        query: {
          salesman: this.modelValue.id,
          limit: 100
        }
      }
    }
  },
  methods: {
    handleLoadingError(err) {
      this.$logException(err, {
        trackError: false,
        message: this.$t('common.error.action.read.multiple', {
          resources: this.$t('pages.user-profile.user-info.tab.sales.title')
        })
      })
    },
    handleHeadersConfig(config) {
      this.$store.dispatch('Config/setLocalConfigurationValue', {
        path: headersConfigPath,
        value: config || {}
      })
    },
    formatTransactionItem(item) {
      if (Number.isFinite(item.amount_total_gross)) {
        item._amount_total_gross = this.$formatCurrency(
          item.amount_total_gross,
          item.currency
        )
      } else {
        item._amount_total_gross = '--'
      }

      if (Number.isFinite(item.total)) {
        item._total = this.$formatCurrency(item.total, item.currency)
      } else {
        item._total = '--'
      }

      if (!item.date) {
        item._date_label = '--'
      } else {
        item._date_label = this.$date.formatDateTimeWithTimezone(
          item.date,
          item.timezone
        )
      }
      return item
    },
    // NOTE: this is a performance optimization in order not to parse in formatters, which seems to be costly in massive data scenarios.
    // The gist is: pre-digest strings, so the call stacks get thinner later. This mutates actual data inside the table
    // As the report was taken from AnalyticsReportsTransactionsItems, it was necessary to do a grouping of the data
    // Used to create the summary (number_sales / avg_sales / total_sales) for the footer
    transformFetchedData(data) {
      let transactions = []
      let transactionsIds = []
      let total = null
      let currency = null
      data.forEach((item) => {
        total += item.amount_total_gross
        if (!currency) currency = item.currency
        this.formatTransactionItem(item)
        if (!transactionsIds.includes(item.transaction_id)) {
          //Create a new transaction item with total
          transactionsIds.push(item.transaction_id)
          transactions.push(
            Object.assign(item, {
              total: item.amount_total_gross,
              items: [item]
            })
          )
        } else {
          //Add items and sum total to transaction
          let transaction = transactions.find(
            (transaction) => transaction.transaction_id === item.transaction_id
          )
          transaction.total += item.amount_total_gross
          transaction.items.push(item)
        }
      })
      transactions = transactions.map((transaction) =>
        this.formatTransactionItem(transaction)
      )
      this.summary = [
        {
          number_sales: transactions.length,
          avg_sales: this.$formatCurrency(
            total / transactions.length,
            currency
          ),
          total_sales: this.$formatCurrency(total, currency)
        }
      ]
      return transactions
    }
  }
}
</script>

<style scoped>
.sales-table :deep(.content),
.sales-table :deep(.header) {
  margin: 0;
  margin-top: 20px;
}
</style>
