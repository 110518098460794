<template>
  <div class="w-full h-full flex flex-col mx-12 mt-12">
    <h2 class="text-xl">
      {{ $t('pages.user_profile.user_info_pos.title') }}
    </h2>
    <el-row class="my-8">
      <el-form-item
        :label="$t('pages.user_profile.user_info_pos.pin.label')"
        class="font-semibold w-64"
        prop="pin"
      >
        <pin-generator
          id="pin"
          :form-pin="localValue.short_code"
          :update-pin="updatePin"
        />
      </el-form-item>
    </el-row>
    <permissions type="user" />
  </div>
</template>

<script>
import PinGenerator from '@components/pin-generator'
import Permissions from './permissions/index.vue'

export default {
  components: {
    PinGenerator,
    Permissions
  },
  props: {
    modelValue: {
      type: Object,
      required: true
    }
  },
  computed: {
    localValue: {
      get() {
        return this.modelValue
      },
      set(v) {
        this.$emit('update:modelValue', v)
      }
    }
  },
  methods: {
    updatePin(short_code) {
      this.localValue.short_code = short_code
    }
  }
}
</script>
