<template>
  <div class="w-full h-full flex flex-col mx-12 mt-12">
    <login
      ref="login"
      :model-value="modelValue"
      @update:modelValue="$emit('update:modelValue', $event)"
    />
    <permissions type="staff" />
  </div>
</template>

<script>
import login from './login/index.vue'
import Permissions from './permissions/index.vue'
export default {
  components: { Permissions, login },
  props: {
    modelValue: {
      type: Object,
      required: true
    }
  },
  data() {
    return {}
  },
  methods: {
    validate(cb) {
      this.$refs.login.validate((valid) => {
        return cb(valid)
      })
    }
  }
}
</script>

<style scoped></style>
