<template>
  <base-card
    class="items-center px-10 mx-2 w-full overflow-hidden"
    style="height: calc(100% - 284px)"
  >
    <div class="flex flex-col p-3">
      <span class="text-lg mb-2">{{
        $t('pages.user_profile.time_tracking_overview.title')
      }}</span>
      <span v-if="hoursPerWeek">{{
        $t('pages.user_profile.time_tracking_overview.hours_per_week.text', {
          hours: hoursPerWeek
        })
      }}</span>
    </div>
    <div
      v-if="!timeEntries.length"
      class="h-full flex flex-col justify-center items-center"
    >
      <img :src="noHoursRecordedImg" width="344" alt="no-hours-recorded" />
      <p
        class="text-base leading-none py-1"
        v-text="$t('pages.user_profile.no_hours')"
      />
    </div>
    <div
      v-else
      class="my-4 divide-y divide-gray-400"
      style="height: calc(100% - 100px)"
    >
      <el-row class="flex p-3 font-bold">
        <el-col>{{
          $t('pages.user_profile.time_tracking_overview.column.date')
        }}</el-col>
        <el-col class="text-right">{{
          $t('pages.user_profile.time_tracking_overview.column.total_worked')
        }}</el-col>
      </el-row>
      <div
        class="overflow-y-auto overflow-x-hidden h-max divide-y divide-gray-400"
        style="height: calc(100% - 29px)"
      >
        <el-row
          v-for="(timeRow, index) in timeEntries"
          :key="index"
          class="flex p-3"
        >
          <el-col>{{
            getFormattedDate(timeRow.date, timeRow.timezone)
          }}</el-col>
          <el-col class="text-right">{{
            formatTime(timeRow.total_worked)
          }}</el-col>
        </el-row>
      </div>
    </div>
  </base-card>
</template>

<script>
import noHoursRecordedImg from '@/assets/illustrations/no-hours-recorded.svg'
import BaseCard from '@/components/base-card'
export default {
  components: { BaseCard },
  props: {
    timeEntries: {
      type: Array,
      default: () => []
    },
    hoursPerWeek: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      noHoursRecordedImg
    }
  },
  methods: {
    formatTime(time) {
      const hours = `${time.hours || 0}${this.$t('common.hour.min')} `
      const minutes = `${time.minutes || 0}${this.$t('common.minute.min')} `
      return `${hours}${minutes}`
    },
    getFormattedDate(date, timezone) {
      return this.$date.formatDateTimeWithTimezone(date, timezone)
    }
  }
}
</script>
<style scoped></style>
