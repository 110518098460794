<template>
  <th-page-wrapper
    background-color="var(--navigation-bg-color)"
    :scroll-content="false"
  >
    <el-form ref="form" :model="userProfile" :rules="rules">
      <div
        class="flex px-5 pt-5 h-screen overflow-y-hidden"
        style="padding-bottom: 135px"
      >
        <div class="flex flex-col mr-10 w-1/3">
          <user-card
            v-model="userProfile"
            class="mb-5"
            :branches="branches"
            :role="role"
          />
          <time-tracking-overview
            :time-entries="timeEntries"
            :hours-per-week="hoursPerWeek"
          />
        </div>
        <user-info ref="info" v-model="userProfile" class="w-2/3" />
      </div>
    </el-form>
    <template #actions>
      <div class="p-4 h-full flex justify-end justify-items-end items-center">
        <el-button size="large" :disabled="resetDisabled" @click="handleReset">
          {{ $t('pages.settings.toolbar.button.reset') }}
        </el-button>

        <el-button
          type="primary"
          size="large"
          :disabled="resetDisabled"
          @click="handleSave"
        >
          {{ $t('common.interactions.buttons.save') }}
        </el-button>
      </div>
    </template>
  </th-page-wrapper>
</template>

<script>
import UserCard from './components/user-card'
import TimeTrackingOverview from './components/time-tracking-overview'
import UserInfo from './components/user-info'
import th from '@tillhub/javascript-sdk'
import safeGet from 'just-safe-get'
import compare from 'just-compare'
import isEmpty from 'just-is-empty'
import { useUserProfileStore } from '@/store/user-profile'
import { storeToRefs } from 'pinia'

export default {
  components: {
    UserCard,
    TimeTrackingOverview,
    UserInfo
  },
  setup() {
    const userProfileStore = useUserProfileStore()
    const {
      userProfile: currentUserProfile,
      branches,
      timeEntries
    } = storeToRefs(userProfileStore)
    return {
      userProfileStore,
      currentUserProfile,
      branches,
      timeEntries
    }
  },
  data() {
    return {
      userProfile: {},
      pristineUserProfile: {},
      rules: {}
    }
  },
  computed: {
    configurationId() {
      return (
        safeGet(this.$store.state.Config, 'clientAccountConfiguration.id') ||
        null
      )
    },
    locations() {
      return safeGet(this.$store.state.Auth, 'locations') || []
    },
    staffId() {
      return safeGet(this.$store.state.Auth, 'staff') || null
    },
    role() {
      return safeGet(this.$store.state.Auth, 'role') || null
    },
    isDirty() {
      return !compare(this.pristineUserProfile, this.userProfile)
    },
    canLeave() {
      return !this.isDirty
    },
    resetDisabled() {
      return !this.isDirty
    },
    hoursPerWeek() {
      return this.userProfile?.metadata?.Arbeitsstunden || null
    }
  },
  async mounted() {
    await this.$store.dispatch('Auth/waitThInitializated')
    this.checkTabRedirect()
    await this.fetch()
  },
  methods: {
    checkTabRedirect() {
      //Solution for changing tabs when the filters from reports change the path
      if (['sales', 'hours'].includes(this.$route.name)) {
        this.$refs.info.selectTab(this.$route.name)
      } else {
        //In case we enter for first time, clean the store
        this.userProfileStore.resetUserProfile()
      }
      //Set store values
      this.userProfile = this.$deepClone(this.currentUserProfile)
    },
    async fetch() {
      try {
        this.loading = true
        if (isEmpty(this.userProfile)) {
          await this.fetchUserProfile()
        }
        if (this.userProfile?.id && isEmpty(this.timeEntries)) {
          await this.fetchTimeTracking()
        }
        this.pristineUserProfile = this.$deepClone(this.userProfile)
        if (this.locations.length && isEmpty(this.branches)) {
          await this.fetchBranches()
        }
      } finally {
        this.loading = false
      }
    },
    async fetchUserProfile() {
      const staff = await this.fetchStaff()
      if (staff?.user) {
        const user = await this.fetchUser(staff?.user)
        this.userProfile = Object.assign({}, staff, {
          username: user.username,
          user_id: user.id,
          user: user.user
        })
        this.userProfileStore.userProfile = this.$deepClone(this.userProfile)
      }
    },
    async fetchStaff() {
      try {
        const { data } = await th.staff().get(this.staffId)
        return data
      } catch (err) {
        let message = null
        if (this.staffId) {
          message = this.$t('common.error.action.read.single', {
            resource: this.$t('common.resource.staff.singular')
          })
        } else {
          message = this.$t('pages.user-profile.error.fetch_fail.no_staff')
        }
        this.$logException(err, {
          trackError: false,
          message
        })
      }
    },
    async fetchUser(userId) {
      try {
        const { data } = await th.users(this.configurationId).get(userId)
        return data
      } catch (err) {
        this.$logException(err, {
          trackError: false,
          message: this.$t('common.error.action.read.single', {
            resource: this.$t('common.resource.user.singular')
          })
        })
      }
    },
    async fetchBranches() {
      try {
        const { branchesV1 } = await this.$resourceFetch('branchesV1')
        this.userProfileStore.branches = branchesV1.filter((branch) =>
          this.locations.includes(branch.id)
        )
      } catch (err) {
        this.$logException(err, {
          trackError: false,
          type: 'error',
          message: this.$t('common.error.action.read.multiple', {
            resources: this.$t('common.resource.branch.plural')
          })
        })
      }
    },
    async fetchTimeTracking() {
      try {
        const { data } = await th.timetracking().get(this.userProfile.id)
        // Order by date descending
        this.userProfileStore.timeEntries = data.sort(
          (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
        )
      } catch (err) {
        this.$logException(err, {
          trackError: false,
          message: this.$t('common.error.action.read.multiple', {
            resources: this.$t('common.resource.timetracking.plural')
          })
        })
      }
    },
    handleReset() {
      this.userProfile = this.$deepClone(this.pristineUserProfile)
    },
    handlePayloadStaff(staff) {
      let response = {
        firstname: staff.firstname,
        lastname: staff.lastname,
        images: staff.images
      }
      if (staff.short_code) response.short_code = staff.short_code
      return response
    },
    handlePayloadUser(user) {
      let response = {
        username: user.username,
        user_id: user.user_id,
        user: Object.assign({}, user.user, {
          email: user.username
        })
      }
      if (user.secret) response.secret = user.secret
      return response
    },
    async handleSave() {
      this.$refs.info.validate(async (valid) => {
        if (!valid) return

        const successMessage = this.$t('common.success.action.update.single', {
          resource: this.$t('common.resource.configuration.singular')
        })
        const errorMessage = this.$t('common.error.action.update.single', {
          resource: this.$t('common.resource.configuration.singular')
        })

        try {
          const payloadUser = this.handlePayloadUser(this.userProfile)
          const payloadStaff = this.handlePayloadStaff(this.userProfile)
          const user = await th
            .users(this.configurationId)
            .put(this.userProfile.user_id, payloadUser)
          const { data } = await th
            .staff()
            .put(this.userProfile.id, payloadStaff)
          this.userProfile = Object.assign({}, data, {
            username: user.data.username,
            user_id: user.data.id,
            user: user.data.user
          })
          this.userProfileStore.userProfile = this.$deepClone(this.userProfile)
          this.pristineUserProfile = this.$deepClone(this.userProfile)
          this.$message({
            type: 'success',
            message: successMessage
          })
        } catch (err) {
          return this.$logException(err, {
            message: errorMessage
          })
        }
      })
    }
  }
}
</script>
<style scoped></style>
